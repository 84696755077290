import * as React from 'react';
import { NextPage } from 'next';
import { ButtonLink, DownloadAppButton } from '@/components/common';

const NotFoundPage: NextPage = () => {
  return <div className='max-w-xl mx-auto text-center my-16 px-6'>
      <h1 className='font-josefin font-bold text-4xl lg:text-5xl'>App Not Found</h1>
      <p className='mt-2 mb-6'>
        It looks like you don{"'"}t have the app installed, follow the links below to download.
      </p>
      <div className='flex flex-col mb-6 sm:flex-row gap-4 justify-center sm:items-center'>
        <DownloadAppButton location='404' os='iOS' />
        <DownloadAppButton location='404' os='Android' />
      </div>
      <div className='flex flex-col sm:flex-row gap-4 justify-center sm:items-center'>
        <ButtonLink href='/'>Return to Homepage</ButtonLink>
      </div>
    </div>;
};

export default NotFoundPage;